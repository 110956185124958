import image1 from '../assets/images/city/airport-2760048_1920.jpg';
import image2 from '../assets/images/city/architecture-3249122_1920.jpg';
import image3 from '../assets/images/city/city-3249095_1920.jpg';
import image4 from '../assets/images/city/schlo-neuhaus-2225180_1920.jpg';

export const carousel = [
  {
    key: 1,
    src: image1,
    alt: 'paderborner flughafen',
  },
  {
    key: 2,
    src: image2,
    alt: 'paderborne architektur',
  },
  {
    key: 3,
    src: image3,
    alt: 'paderborner stadt',
  },
  {
    key: 4,
    src: image4,
    alt: 'paderborner schloss',
  },
];
