import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { features } from '../data/Features';

export default function Features() {
  const featuresItems = features;

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div id="about-us" className="bg-white py-18 sm:py-24">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="hidden md:visible absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[32rem] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div
          className="mx-auto max-w-2xl lg:text-center"
          data-aos="zoom-in"
          data-aos-delay="50"
          data-aos-offset="0"
        >
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Vertrauen durch Erfahrung und Engagement
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Verlassen Sie sich auf den Paderborner Sicherheitsdienst, um ein
            Höchstmaß an Schutz für Ihr Unternehmen oder Ihr Zuhause zu
            gewährleisten.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-6xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
            {featuresItems.map((feature) => (
              <div
                data-aos="fade-up"
                data-aos-delay={feature.aosDelay}
                data-aos-offset="0"
                key={feature.key}
                className="relative pl-16"
              >
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-600">
                    <feature.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd
                  className="mt-2 text-sm leading-7 text-gray-600"
                  dangerouslySetInnerHTML={{
                    __html: feature.description,
                  }}
                ></dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
