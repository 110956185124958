import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from 'react';
import { carousel } from '../data/Carousel';
import { services } from '../data/Services';

export default function Services() {
  const servicesItem = services;
  const carouselItem = carousel;

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItem.length);
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [carouselItem.length]);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div id="services" className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2
                className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
                data-aos="zoom-in"
                data-aos-delay="50"
                data-aos-offset="0"
              >
                Unsere Dienstleistungen
              </h2>
              <p
                className="mt-6 text-lg leading-8 text-gray-600"
                data-aos="zoom-in"
                data-aos-delay="50"
                data-aos-offset="0"
              >
                <i className="font-extrabold underline">Paderborner</i>{' '}
                Sicherheitsdienst - Ihr Sicherheitspartner in Paderborn. Wir
                bieten zuverlässige Objektbewachung, professionellen
                Personenschutz und sichere Veranstaltungslösungen. Vertrauen Sie
                unserer Expertise für umfassende Sicherheit.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {servicesItem.map((item) => (
                  <div
                    key={item.name}
                    className="relative pl-9"
                    data-aos="fade-right"
                    data-aos-delay={item.aosDelay}
                    data-aos-offset="0"
                  >
                    <dt className="inline font-semibold text-gray-900">
                      <item.icon
                        className="absolute left-1 top-1 h-5 w-5 text-blue-600"
                        aria-hidden="true"
                      />
                      {item.name}
                    </dt>{' '}
                    <dd className="block">{item.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="w-[100%] lg:w-[48rem] h-fit max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 md:-ml-4 lg:-ml-0 lg:mt-16">
            <div className="relative overflow-hidden w-full">
              <div
                className="flex transition-transform ease-in-out duration-500 transform"
                style={{
                  transform: `translateX(-${currentIndex * 100}%)`,
                }}
              >
                {carouselItem.map((item, index) => (
                  <img
                    key={item.key}
                    src={item.src}
                    alt={item.alt}
                    loading="lazy"
                    className="w-full rounded-xl"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[32rem] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  );
}
