import {
  PresentationChartLineIcon,
  ArrowPathIcon,
  BanknotesIcon,
  ClipboardDocumentListIcon,
  CubeTransparentIcon,
  LockClosedIcon,
} from '@heroicons/react/24/outline';

export const features = [
  {
    key: 1,
    aosDelay: 300,
    name: 'Unsere Mission',
    description: `
        Wir sind der Paderborner Sicherheitsdienst und wir sind bereit, für Ihre Sicherheit zu sorgen. 
        Mit unserem qualifizierten Team bieten wir erstklassige Sicherheitslösungen für Unternehmen und Privatpersonen.
      `,
    icon: PresentationChartLineIcon,
  },
  {
    key: 2,
    aosDelay: 500,
    name: 'Unsere Werte',
    description: `
      Bei Paderborner Sicherheitsdienst sind wir stolz auf unsere starken Werte, 
      die uns leiten und unsere Arbeitsweise prägen. Diese Werte sind:
    `,
    icon: BanknotesIcon,
  },
  {
    key: 3,
    aosDelay: 700,
    name: 'Unser Team',
    description: `
        Unser Team besteht aus hochqualifizierten Sicherheitskräften mit umfangreicher Erfahrung in verschiedenen Bereichen der Sicherheitsbranche. 
        Jedes Mitglied unseres Teams ist speziell geschult, um professionelle Sicherheitsdienstleistungen zu erbringen und auf alle Situationen angemessen zu reagieren.
      `,
    icon: ArrowPathIcon,
  },
  {
    key: 4,
    aosDelay: 300,
    name: 'Unsere Zielsetzung',
    description: `
        Unser oberstes Ziel ist es, ein Gefühl der Sicherheit und des Vertrauens zu schaffen. 
        Wir wissen, dass jedes Sicherheitsbedürfnis einzigartig ist, und daher arbeiten wir eng mit unseren Kunden zusammen, 
        um maßgeschneiderte Sicherheitslösungen anzubieten.
      `,
    icon: CubeTransparentIcon,
  },
  {
    key: 5,
    aosDelay: 500,
    name: 'Unsere Prinzipien',
    description: `
        Zuverlässigkeit <br /> Professionalität <br /> Kundenzufriedenheit <br /> Vertrauen <br /> Integrität
      `,
    icon: ClipboardDocumentListIcon,
  },
  {
    key: 6,
    aosDelay: 700,
    name: 'Unser Einheit',
    description: `
        Wir legen großen Wert auf kontinuierliche Weiterbildung und halten uns stets auf dem neuesten Stand der Sicherheitsstandards und -technologien. 
        So können wir unseren Kunden die bestmögliche Sicherheit bieten.
      `,
    icon: LockClosedIcon,
  },
];
